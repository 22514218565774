.swiper {
  width: 100%;
  /* height: 100vh; */
}

.swiper-slide {
  background: #000000b0;
}

.slider-container {
  position: relative;
  display: flex;
  align-items: center;

  margin: 0 auto;
}

.custom-prev-btn,
.custom-next-btn {
  background-color: #333;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 18px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0; /* Hide buttons by default */
  scale: 0.5; /* Scale down by default */
  transition: opacity 0.3s, scale 0.3s;
  z-index: 10;
}

.custom-prev-btn {
  left: 0;
}

.custom-next-btn {
  right: 0;
}

.mySwiper {
  width: 100%;
}

.slider-container:hover .custom-prev-btn,
.slider-container:hover .custom-next-btn {
  opacity: 1;
  scale: 1;
}

.mySwiperms {
  height: 100%;
}
