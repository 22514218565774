@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --tw-bg-opacity: 0.5; /* Adjust the opacity as needed */
}

.pafm {
  font-family: "YourCustomFonts" !important;
  font-size: 16px !important;
}

* {
}

body {
  background-color: #090809 !important;
  overflow-x: hidden;
}

.mainfont {
  font-family: "YourCustomFont" !important;
}

@font-face {
  font-family: "YourCustomFont";
  src: url("./Fonts/Resolute\ NF\ W01\ Regular.ttf") format("WOFF");
}

@font-face {
  font-family: "YourCustomFonts";
  src: url("./Fonts/Aux\ Mono.ttf") format("WOFF");
}

@font-face {
  font-family: "YourCustomFontst";
  src: url("./Fonts/AeonikTRIAL-Bold.otf") format("WOFF");
}

.mems {
  font-family: "YourCustomFont";
  color: #fff;
  position: relative;
  top: 13px;
}

.slider_under {
  font-family: "YourCustomFont";
  color: #e3a111;
  font-size: 30px;
  text-align: center;
  text-shadow: #000 4px 0 0, #000000 -2.61457px -3.02721px 0,
    #000000 -1.78435px -3.57996px 0, #000000 -0.843183px -3.91012px 0,
    #000000 0.150409px -3.99717px 0, #000000 1.13465px -3.8357px 0,
    #000000 2.04834px -3.43574px 0, #000000 2.83468px -2.82216px 0,
    #000000 3.44477px -2.03312px 0, #000000 3.84068px -1.11766px 0,
    #000000 3.9978px -0.132717px 0, #000000 0 6px 0;
}

.centerall {
  text-align: center;
  justify-content: center;
  align-items: center;
}

/* Additional font-face rules if you have more fonts */

/* Define global font styles */
.matrix-text {
  color: rgb(255, 255, 255);
  font-family: Courier New, Courier, monospace;
  text-shadow: 0 0 2px rgb(4, 89, 201), 0 0 4px rgb(120, 64, 239),
    0 0 6px rgb(166, 29, 252), 0 0 8px rgb(108, 0, 151),
    0 0 10px rgb(140, 60, 215), 0 0 12px rgb(60, 0, 143),
    0 0 14px rgb(102, 1, 148), 0 0 16px rgb(113, 0, 166);
}

.image_anos {
  width: 60%;
}
@media screen and (min-width: 1000px) {
  .image_anos {
    width: 50%;
  }
}

.footer_bg {
  background-color: #020916;
}

.footer-bottom-wrap {
  background-image: url("./Components/images/footertop.webp");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 4.375rem 2.75rem 2.625rem;
  position: absolute;
  inset: auto 0% 0%;
}

.BACKPLANET {
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  position: sticky;
}

.btc {
  position: sticky;
  top: 40px;
}

.all_righ {
  font-family: "YourCustomFonts";
  color: #aeb0b2;
  font-size: 14px !important;
  font-weight: 100;
}
.all_righm {
  font-family: "YourCustomFonts";
  color: #aeb0b2;
  font-size: 10px !important;
  font-weight: 100;
}

.makeitend {
  justify-content: end;
  align-items: end;
  text-align: end;
}

.all_righsl {
  font-family: "YourCustomFonts";
  color: #aeb0b2;
  font-size: 30px !important;
  font-weight: 100;
  position: relative;
  text-align: center;
  left: 20px;
  top: 10px;
}

.back_of_faq {
  background-color: #070809;
}

.FAQ_TEXTt {
  font-family: "YourCustomFont";
  color: #da2d0b;
  font-size: 120px;
  text-align: center;
  letter-spacing: 10px;
  text-shadow: #000 4px 0 0, #000000 3.87565px 0.989616px 0,
    #000000 3.51033px 1.9177px 0, #17004d 2.92676px 2.72656px 0,
    #000000 2.16121px 3.36588px 0, #484848 -0.712984px 3.93594px 0,
    #484848 -1.66459px 3.63719px 0, #484848 -2.51269px 3.11229px 0,
    #484848 -3.20457px 2.39389px 0, #484848 -3.69721px 1.52664px 0,
    #484848 -3.95997px 0.56448px 0, #484848 -3.97652px -0.432781px 0,
    #484848 -3.74583px -1.40313px 0, #484848 -3.28224px -2.28625px 0,
    #484848 -2.61457px -3.02721px 0, #484848 -1.78435px -3.57996px 0,
    #484848 -0.843183px -3.91012px 0, #484848 0.150409px -3.99717px 0,
    #484848 2.04834px -3.43574px 0, #484848 3.44477px -2.03312px 0,
    #484848 3.84068px -1.11766px 0, #484848 3.9978px -0.132717px 0,
    #484848 0 6px 0;
}

.FAQ_TEXTbot {
  font-family: "YourCustomFont";
  color: #ffbb00;
  font-size: 40px;
}

@media screen and (min-width: 1000px) {
  .FAQ_TEXTbot {
    font-family: "YourCustomFont";
    color: #ffbb00;
    font-size: 64px;
  }
}
.FAQ_TEXTs {
  font-family: "YourCustomFont";
  color: #ffbb00;
  font-size: 54px;
  position: relative;
  left: 20px;
}

.FAQ_TEXTsm {
  font-family: "YourCustomFont";
  color: #ffffff;
  font-size: 100px;
  position: relative;
  left: 20px;
}

.FAQ_TEXTsmm {
  font-family: "YourCustomFont";
  color: #ffffff;
  font-size: 50px;
  position: relative;
  left: 10px;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  font-family: "YourCustomFontst" !important;
  background-color: #000000 !important;
  color: rgb(255, 255, 255) !important;
  border-left: 2px solid #570074 !important;
  border-right: 2px solid #570074 !important;
}

.about_bg {
  background-color: #4e4e4e51;
  border-left: 5px solid #ffbb00 !important;
}

.about_under {
  /* font-family: "YourCustomFonts"; */
  color: #fff;
  padding: 10px;
  font-size: 25px;
  position: relative;
  left: 10px;
  text-align: justify;
}
.about_underm {
  /* font-family: "YourCustomFonts"; */
  color: #fff;
  padding: 10px;
  font-size: 18px;
  position: relative;
}

.team_head {
  font-family: "YourCustomFonts";
  color: #fff;
  font-size: 35px;
}

.team_headu {
  font-family: "YourCustomFonts";
  color: #aeb0b2;
  font-size: 20px;
}

.mem_mar {
  /* font-family: "YourCustomFonts"; */
  color: #f0e000;
  font-size: 20px;
}

.solana_launcht {
  font-family: "YourCustomFonts";
  color: #e0e0e0;
  font-size: 14px;
  text-align: justify;
}

@media screen and (min-width: 1000px) {
  .solana_launcht {
    font-family: "YourCustomFonts";
    color: #e0e0e0;
    font-size: 16px;
  }
}

.back {
  background-color: #000;
  border-bottom: 2px solid #565656;
  border-left: 2px solid #565656;
}

.cards {
  padding-left: 10px;
  padding-bottom: 10px;
}

.border_image_team {
  background-color: #000;
  border-top: 2px solid #565656;
  border-right: 2px solid #565656;
  border-bottom: 2px solid #565656;
}

.backofBots {
  background-color: #000;
}

/* From Uiverse.io by Saad3092003 */
.obj {
  position: relative;
  width: 200px;
  height: 200px;
  /* background-color: rgb(96, 96, 232); */
  transform-style: preserve-3d;
  transition: 0.5s all;
  transform: rotateX(-25deg) rotateY(20deg);
}

.objchild {
  animation: rotate 4s infinite linear;
  transform-style: preserve-3d;
  position: absolute;
  width: 100%;
  height: 100%;
  /* background:linear-gradient( to top,rgb(11, 11, 102),rgb(96, 96, 232) ) ; */
}

.objchild::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  /* background: rgb(37, 101, 230); */
  /* background: radial-gradient(center center, farthest-side , #9993, transparent); */
  filter: blur(20px);
  box-shadow: 0 0 200px 15px white;
  transform: rotateX(90deg) scale(1.1) translateZ(-120px);
}

.inn6 {
  position: absolute;
  width: 100%;
  height: 100%;

  transform: rotateX(90deg) translateZ(100px);
  animation: updown 4s infinite ease-in-out;
}

@keyframes rotate {
  0% {
    transform: rotate3d(0, 1, 0, 0deg);
  }

  100% {
    transform: rotate3d(0, 1, 0, 360deg);
  }
}

@keyframes updown {
  0% {
    transform: translateY(100px) rotateX(90deg) translateZ(100px);
  }

  50% {
    transform: translateY(200px);
  }

  100% {
    transform: translateY(100px) rotateX(450deg) translateZ(100px);
  }
}

.backofslide1 {
  background-image: url("./Components/SliderImage/L4.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  position: relative; /* Position relative for the glass effect */
  overflow: hidden; /* Prevents overflow if needed */
}

.glass-effect {
  height: 100%; /* Full height of the parent */
  width: 100%; /* Full width of the parent */
  backdrop-filter: blur(3px); /* Blurs the background */
  background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent white */
  position: absolute; /* Position absolute to cover parent */
  top: 0; /* Align to top */
  left: 0; /* Align to left */
  z-index: 1; /* Ensure it’s above the background */
}

/* Styles for the text */
.backofslide1 .top {
  position: relative; /* Position relative for stacking context */
  color: #fff; /* Text color */

  z-index: 2; /* Make sure text is above the glass effect */
}

.backofslide2 {
  background-image: url("./Components/SliderImage/L5.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  position: relative; /* Position relative for the glass effect */
  overflow: hidden; /* Prevents overflow if needed */
}

.glass-effect {
  height: 100%; /* Full height of the parent */
  width: 100%; /* Full width of the parent */
  backdrop-filter: blur(3px); /* Blurs the background */
  background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent white */
  position: absolute; /* Position absolute to cover parent */
  top: 0; /* Align to top */
  left: 0; /* Align to left */
  z-index: 1; /* Ensure it’s above the background */
}

/* Styles for the text */
.backofslide2 .top {
  position: relative; /* Position relative for stacking context */
  color: #fff; /* Text color */
  font-size: 2.5rem; /* Adjust size as needed */
  text-align: center; /* Center text */
  z-index: 2; /* Make sure text is above the glass effect */
  padding: 20px; /* Add padding if needed */
}

.backofslide3 {
  background-image: url("./Components/SliderImage/L6.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  position: relative; /* Position relative for the glass effect */
  overflow: hidden; /* Prevents overflow if needed */
}

.glass-effect {
  height: 100%; /* Full height of the parent */
  width: 100%; /* Full width of the parent */
  backdrop-filter: blur(3px); /* Blurs the background */
  background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent white */
  position: absolute; /* Position absolute to cover parent */
  top: 0; /* Align to top */
  left: 0; /* Align to left */
  z-index: 1; /* Ensure it’s above the background */
}

/* Styles for the text */
.backofslide3 .top {
  position: relative; /* Position relative for stacking context */
  color: #fff; /* Text color */
  font-size: 2.5rem; /* Adjust size as needed */
  text-align: center; /* Center text */
  z-index: 2; /* Make sure text is above the glass effect */
  padding: 20px; /* Add padding if needed */
}

/* From Uiverse.io by SouravBandyopadhyay */
.notification {
  display: flex;
  flex-direction: column;
  isolation: isolate;
  position: relative;
  width: 150px;
  height: 50px;
  background: #222222a0;
  border-radius: 1rem;

  font-size: 16px;
  --gradient: linear-gradient(to bottom, #560065, #ffffff, #000000);
  --color: #32a6ff;
}

.notification:before {
  justify-content: center;
  align-items: center;
  content: "";

  inset: 0.0625rem;
  border-radius: 0.9375rem;
  background: #18181b;
  z-index: 2;
}

.notification:after {
  position: absolute;
  content: "";
  width: 0.25rem;
  inset: 0.65rem auto 0.65rem 0.5rem;
  border-radius: 0.125rem;
  background: var(--gradient);
  transition: transform 300ms ease;
  z-index: 4;
}

.notification:hover:after {
  transform: translateX(0.15rem);
}

.notititle {
  color: var(--color);
  padding: 0.65rem 0.25rem 0.4rem 1.25rem;
  font-weight: 500;
  font-size: 1.1rem;
  transition: transform 300ms ease;
  z-index: 5;
}

.notification:hover .notititle {
  transform: translateX(0.15rem);
}

.notibody {
  color: #99999d;
  padding: 0 1.25rem;
  transition: transform 300ms ease;
  z-index: 5;
}

.notification:hover .notibody {
  transform: translateX(0.25rem);
}

.notiglow,
.notiborderglow {
  position: absolute;
  width: 20rem;
  height: 20rem;
  transform: translate(-50%, -50%);
  background: radial-gradient(
    circle closest-side at center,
    white,
    transparent
  );
  opacity: 0;
  transition: opacity 300ms ease;
}

.notiglow {
  z-index: 3;
}

.notiborderglow {
  z-index: 1;
}

.notification:hover .notiglow {
  opacity: 0.1;
}

.notification:hover .notiborderglow {
  opacity: 0.1;
}

/* From Uiverse.io by Madflows */
.button {
  position: relative;

  height: 3rem;
  font-family: "YourCustomFonts";
  padding: 0 2rem;
  border-radius: 1rem;
  background: #ff5500;
  background-size: 400%;
  color: #fff;
  border: none;
  cursor: pointer;
}

.button:hover::before {
  transform: scaleX(1);
}

.button-content {
  position: relative;
  z-index: 1;
}

.button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: scaleX(0);
  transform-origin: 0 50%;
  width: 100%;
  height: inherit;
  border-radius: inherit;
  background: linear-gradient(82.3deg, #ffbf9f4e, #ffbd9baf);
  transition: all 0.475s;
}

.centeritall {
  justify-content: center;
  display: flex;
}

.back_topm {
  background-color: #000;
}

.card_back {
  background: rgb(0, 0, 0);

  padding: 10px;
  border-top: 2px solid #4f4f4f;
  border-right: 2px solid #4f4f4f;
  border-radius: 1px;
}

.all_righsv {
  font-family: "YourCustomFonts";
  color: #ffffff;
  padding: 10px;
  font-size: 16px !important;
  font-weight: 100;
}

/* From Uiverse.io by MuhammadHasann */
.card {
  --white: hsl(0, 0%, 100%);
  --black: hsl(240, 15%, 9%);
  --paragraph: hsl(0, 0%, 83%);
  --line: hsl(24, 100%, 50%);
  --primary: hsl(266, 92%, 58%);

  position: relative;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  padding: 1rem;

  background-color: hsla(240, 15%, 9%, 1);
  background-image: radial-gradient(
      at 88% 40%,
      hsla(240, 15%, 9%, 1) 0px,
      transparent 85%
    ),
    radial-gradient(at 49% 30%, hsla(240, 15%, 9%, 1) 0px, transparent 85%),
    radial-gradient(at 14% 26%, hsla(240, 15%, 9%, 1) 0px, transparent 85%),
    radial-gradient(at 0% 64%, hsla(263, 93%, 56%, 1) 0px, transparent 85%),
    radial-gradient(at 41% 94%, hsla(284, 100%, 84%, 1) 0px, transparent 85%),
    radial-gradient(at 100% 99%, hsla(306, 100%, 57%, 1) 0px, transparent 85%);

  border-radius: 1rem;
  box-shadow: 0px -16px 24px 0px rgba(255, 255, 255, 0.25) inset;
}

.card .card__border {
  overflow: hidden;
  pointer-events: none;

  position: absolute;
  z-index: -10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background-image: radial-gradient(
      at 88% 40%,
      hsla(240, 15%, 9%, 1) 0px,
      transparent 85%
    ),
    radial-gradient(at 49% 30%, hsla(240, 15%, 9%, 1) 0px, transparent 85%),
    radial-gradient(at 14% 26%, hsla(240, 15%, 9%, 1) 0px, transparent 85%),
    radial-gradient(at 0% 64%, rgb(138, 138, 138) 0px, transparent 85%),
    radial-gradient(at 41% 94%, rgb(62, 0, 84) 0px, transparent 85%),
    radial-gradient(at 100% 99%, rgb(88, 88, 88) 0px, transparent 85%);
  border-radius: 0rem;
}

.card .card__border::before {
  content: "";
  pointer-events: none;

  position: fixed;
  z-index: 200;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%), rotate(0deg);
  transform-origin: left;

  width: 200%;
  height: 10rem;
  background-image: linear-gradient(
    0deg,
    hsla(0, 0%, 100%, 0) 0%,
    hsl(277, 95%, 60%) 40%,
    hsl(277, 95%, 60%) 60%,
    hsla(0, 0%, 40%, 0) 100%
  );

  animation: rotate 8s linear infinite;
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.card .card_title__container .card_title {
  font-size: 18px;
  width: 100%;
  /* font-family: "YourCustomFonts"; */
  font-size: 20px;
  color: var(--white);

  white-space: nowrap; /* Prevents text from wrapping */
  overflow: hidden;    /* Hides any overflowing text */
  text-overflow: ellipsis; /* Applies ellipsis (...) at the point of overflow */
  display: block;      /* Ensures the element respects the width */
  width: 100%;         /* You can adjust this width as needed or use a specific measurement like 'px', '%' etc */
}

.card .card_title__container .card_paragraph {
  margin-top: 0.25rem;
  width: 100%;
  font-size: 0.5rem;
  color: var(--paragraph);
}

.card .line {
  width: 100%;

  background-color: var(--line);
}

.card .card__list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.card .card__list .card__list_item {
}

.card .card__list .card__list_item .check {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 1rem;
  height: 1rem;
  background-color: var(--primary);

  border-radius: 50%;
}

.card .card__list .card__list_item .check .check_svg {
  fill: var(--black);
}

.card .card__list .card__list_item .list_text {
  font-size: 0.75rem;
  color: var(--white);
}

.upand_down {
  display: inline-block; /* Ensures it's inline, change to block for full width */
  animation: moveUpDown 6s ease-in-out infinite;
}

@keyframes moveUpDown {
  0% {
    transform: translateY(0); /* Starting position */
  }
  50% {
    transform: translateY(-20px); /* Move 20px up */
  }
  100% {
    transform: translateY(0); /* Back to starting position */
  }
}

.solana_launch {
  font-family: "YourCustomFont";
  color: #ee37ff;
  font-size: 25px;
}

@media screen and (min-width: 1000px) {
  .solana_launch {
    font-family: "YourCustomFont";
    color: #ee37ff;
    font-size: 45px;
  }
}

.solana_launch1 {
  font-family: "YourCustomFont";
  color: #eaab00;
  font-size: 25px;
}
@media screen and (min-width: 1000px) {
  .solana_launch1 {
    font-family: "YourCustomFont";
    color: #eaab00;
    font-size: 45px;
  }
}
.solana_launch2 {
  font-family: "YourCustomFont";
  color: #ff3e3e;
  font-size: 25px;
}
@media screen and (min-width: 1000px) {
  .solana_launch2 {
    font-family: "YourCustomFont";
    color: #ff3e3e;
    font-size: 45px;
  }
}
.solana_launch3 {
  font-family: "YourCustomFont";
  color: #3ed5ff;
  font-size: 45px;
}
.solana_launch3m {
  font-family: "YourCustomFont";
  color: #3ed5ff;
  font-size: 25px;
}

.lines {
  background-color: #ffffff;
}
.solana_launch12 {
  font-family: "YourCustomFonts";
  color: #0091ff;
  font-size: 20px;
}

.top_fixed_navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  padding: 20px 20px;
  transition: background-color 0.3s ease, backdrop-filter 0.3s ease;

  /* Initial state: no background or glass effect */
  background-color: transparent;
  backdrop-filter: none;
  -webkit-backdrop-filter: none;
}

.top_fixed_navbar.scrolling {
  /* When scrolled: glass-like background */
  background: rgba(0, 0, 0, 0.3); /* Semi-transparent */
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.2); */
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
}

.top_fixed_navbar {
  position: fixed;
  top: 0;
  width: 100%;

  z-index: 1000;
  transition: background-color 0.3s;
}

.top_fixed_navbar.scrolling {
  background-color: rgba(0, 0, 0, 0.3);
}

.desktop-nav {
  display: none;
}

.mobile-nav {
  display: flex;
  justify-content: flex-end;
}

@media (min-width: 600px) {
  .desktop-nav {
    display: flex;
  }

  .mobile-nav {
    display: none;
  }
}

.all_rights {
  margin-left: 20px;
  text-decoration: none;
  color: #333;
  font-size: 16px;
}

.mems {
  font-size: 24px;
  color: #ffffff;
}

.css-1160xiw-MuiPaper-root-MuiDrawer-paper {
  background-color: #000000d5 !important;
  color: #fff !important;
  width: 50%;
}

.css-10hburv-MuiTypography-root {
  margin: 0;
  text-align: center !important;
  font-weight: 400;
  font-size: 25px !important;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  display: block;
}

.css-1ei1svh-MuiButtonBase-root-MuiIconButton-root {
  color: #ffffff !important;
}

.centeritall {
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
}

.FAQ_TEXT {
  font-family: "YourCustomFont";
  color: #ffbb00;
  font-size: 40px;
}

@media screen and (min-width: 1000px) {
  .FAQ_TEXT {
    font-family: "YourCustomFont";
    color: #ffbb00;
    font-size: 80px;
  }
}

.upand_down1 {
  display: inline-block; /* Ensures it's inline, change to block for full width */

  animation: moveLeftRight 6s ease-in-out infinite;
}

@keyframes moveLeftRight {
  0% {
    transform: translate3d(0, 0, 0); /* Starting position */
  }
  50% {
    transform: translateX(-10px); /* Move 100px to the left */
  }
  100% {
    transform: translate3d(0, 0, 0); /* Back to starting position */
  }
}

.upand_down2 {
  display: inline-block; /* Ensures it's inline, change to block for full width */

  animation: moveRightLeft 6s ease-in-out infinite;
}

@keyframes moveRightLeft {
  0% {
    transform: translate3d(0, 0, 0); /* Starting position */
  }
  50% {
    transform: translateX(-10px); /* Move 100px to the left */
  }
  100% {
    transform: translate3d(0, 0, 0); /* Starting position */
  }
}

.upand_down4 {
  display: inline-block; /* Ensures it's inline, change to block for full width */

  animation: moveRightLeft 6s ease-in-out infinite;
}

@keyframes moveRightLeft {
  0% {
    transform: translate3d(0, 0, 0); /* Starting position */
  }
  50% {
    transform: translateX(-10px); /* Move 100px to the left */
  }
  100% {
    transform: translate3d(0, 0, 0); /* Starting position */
  }
}

.upand_down3 {
  display: inline-block; /* Ensures it's inline, change to block for full width */
  animation: moveDownUp 6s ease-in-out infinite;
}

@keyframes moveDownUp {
  0% {
    transform: translate3d(0, 0, 0); /* Starting position */
  }
  50% {
    transform: translateY(15px); /* Move 20px down */
  }
  100% {
    transform: translate3d(0, 0, 0); /* Starting position */
  }
}

.upand_down5 {
  display: inline-block; /* Ensures it's inline, change to block for full width */
  animation: moveRightLeft 6s ease-in-out infinite;
}

@keyframes moveRightLeft {
  0% {
    transform: translate3d(0, 0, 0); /* Starting position */
  }
  50% {
    transform: translateX(-15px); /* Move 15px to the right */
  }
  100% {
    transform: translate3d(0, 0, 0); /* Starting position */
  }
}

.all_righs {
  /* font-family: "YourCustomFonts"; */
  color: #aeb0b2;
  font-size: 16px !important;
  font-weight: 100;
  text-decoration: none; /* No underline initially */
  transition: color 0.3s ease; /* Smooth transition for color change */
}

.all_righs:hover {
  color: #ffffff; /* Change this to the desired hover color */
  text-decoration: none; /* Ensures no underline on hover */
}

.css-67l5gl {
  background-color: #202020 !important;
  padding-top: 10px;
  border-left: 5px solid #ffbb00 !important;
  border-top: 0 !important;
  border-right: 0 !important;
  border-bottom: 0 !important;
}

.MuiAccordionSummary-expandIconWrapper {
  margin-top: -10px;
}

.MuiAccordionSummary-expandIconWrapper svg {
  color: #ffbb00 !important;
}

.css-9l3uo3 {
  color: #e0e0e0 !important;
  font-weight: 300 !important;
}

.css-1ab2xsx {
  background-color: #000 !important;
}

.css-vubbuv {
  color: #fff !important;
}

.css-yb0lig {
  color: #fff !important;
}
